import React from "react";
import GlossaryCard from "../../components/GlossaryCard/GlossaryCard";

function GlossariesList({ data, letter }) {
  let fData = data?.allMdx.edges.filter((el) => {
    return (
      el.node.frontmatter.gPath?.charAt(0).toLowerCase() === letter.toLowerCase()
    );
  });

  return (
    <>
      {fData && (fData[0] && (
        <div className="box-cover" key={"box"+letter} id={letter}>
          <h1 className="header">{letter}</h1>
          <div className="glossary-container" >
            {fData.map((edge) => <GlossaryCard
              key={edge.node.frontmatter.id}
              name={edge.node.frontmatter.name}
              thumbnail={edge.node.frontmatter.thumbnail}
              title={edge.node.frontmatter.title}
              type={edge.node.frontmatter.type}
              link={"/marketing-glossary/" + edge.node.frontmatter.gPath}
              className="glossary"
            />
            )}
          </div>

        </div>
      ))}
    </>
  );
}

export default GlossariesList;
