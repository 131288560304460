import React from "react";

import "./styles.scss";

const GlossaryCard = (props) => {
  const { title, name, link } = props;

  return (
    <div className="content">
      <div className="title">
        <a href={link} target="_blank" rel="noopener noreferrer">
          {name}
        </a>
      </div>
    </div>
  );
};

export default GlossaryCard;
